import React, { useCallback } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import styled from '@emotion/styled';
import { useRecoilState } from 'recoil';
import { posState } from '../../state/pos';
import { cartState, defaultCartState } from '../../state/cart';
import { Typography } from '@mui/material';

const PoSSelectorContainer = React.memo(styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`);

const PoSSelector: React.FC = () => {
  const [pos, setPos] = useRecoilState(posState);
  const [, setCart] = useRecoilState(cartState);

  const handleChange = useCallback((event: SelectChangeEvent) => {
    const posId = event.target.value;
    const selectedPos = pos.list.find(({ id }) => id === posId);
    if (selectedPos) {
      setPos(prev => ({ ...prev, current: selectedPos }));
      setCart(defaultCartState);
    }
  }, [pos.list, setCart, setPos]);

  return (
    <PoSSelectorContainer>
      <Typography sx={style.label} variant='caption'>
        Selecione a loja
      </Typography>
      <Select
        onChange={handleChange}
        sx={style.select}
        value={pos.current?.id.toString()}
      >
        {pos.list.map(({ id, name }) => (
          <MenuItem key={id} value={id}>{name}</MenuItem>
        ))}
      </Select>
    </PoSSelectorContainer>
  );
};

const style = {
  label: {
    position: 'absolute',
    top: '-60px',
    width: '96%',
    textTransform: 'uppercase',
    color: 'custom.white',
    fontWeight: 'bold',
    fontSize: '14px',
  },
  select: {
    width: '96%',
    position: 'absolute',
    backgroundColor: 'custom.white',
    top: '-36px',
    height: '52px',
  }
};

export default React.memo(PoSSelector);
  

