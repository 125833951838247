import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import grey from '@mui/material/colors/grey';

const EmptyCart = React.memo(() => {
  return (
    <Box sx={style.empty}>
      <img src='/assets/empty-cart.svg' alt='Carrinho vazio' />
      <Typography variant='h5' sx={style.emptyText} color={grey[400]}>
        Explore nossa loja e encontre algo especial para adicionar!
      </Typography>
    </Box>
  );
});

const style = {
  empty: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    '> img': {
      margin: 'auto',
      marginTop: '64px',
      width: '60%',
    }
  },
  emptyText: {
    textAlign: 'center',
    width: '80%',
    margin: 'auto',
    fontWeight: 700,
    marginTop: '24px',
    fontFamily: "'Roboto Condensed', sans-serif",
  }
};


export default EmptyCart;