import { atom } from 'recoil';
import { AlertType, DefaultAlertStates } from '../utils/alert';

export type AlertAtom = {
  type: AlertType;
  clickHandler?: () => void;
}

export const defaultAlertState: AlertAtom = {
  type: DefaultAlertStates.CLOSED,
  clickHandler: undefined,
};

export const alertState = atom<AlertAtom>({
  key: 'alertState',
  default: defaultAlertState,
});
