import React, { useEffect, useMemo } from 'react';
import Skeleton from '@mui/material/Skeleton';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import HorizontalRadio, { HorizontalRadioProps } from '../../component/HorizontalRadioProps';
import { useRecoilState } from 'recoil';
import { couponState, useRefreshCoupons, useSelectCoupon } from '../../state/coupon';
import { validateCoupon } from '../../utils/coupon';
import { useCartValue } from '../../state/cart';
import { alertState } from '../../state/alert';
import { CouponAlertStates } from '../../utils/alert';
import { realToCents } from '../../utils/conversion';

const CouponsSection: React.FC = () => {
  const [coupon] = useRecoilState(couponState);
  const [, setAlert] = useRecoilState(alertState);
  const refreshCoupons = useRefreshCoupons();
  const selectCoupon = useSelectCoupon();
  const cartValue = useCartValue();

  // when comes to screen reset selected coupon
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { selectCoupon(undefined); }, []);

  useEffect(() => { refreshCoupons(); }, [refreshCoupons]);

  const coupons = useMemo<HorizontalRadioProps[]>(() => [
    { 
      image: LocalActivityIcon,
      title: 'Sem Cupom',
      checked: coupon.selected === undefined,
      onClick: () => selectCoupon(undefined)
    },
    ...(coupon.list.loading ?
      Array<HorizontalRadioProps>() :
      coupon.list.items.map((c) => ({
        image: LocalActivityIcon,
        title: c.description,
        checked: coupon.selected && coupon.selected.id === c.id,
        onClick: () => {
          if (!validateCoupon(c, realToCents(cartValue))) {
            return setAlert({ type: CouponAlertStates.UNSELECTED_COUPON_ERROR })
          }
          selectCoupon(c);
        }
      })))
  ], [cartValue, coupon.list.items, coupon.list.loading, coupon.selected, selectCoupon, setAlert]);

  return <>
    {coupons.map((c) => (
      <HorizontalRadio key={c.title} {...c} />
    ))}
    {coupon.list.loading && <Skeleton sx={style.skeleton} animation='wave' variant="rectangular" />}
  </>
};

const style = {
  skeleton: {
    margin: '4px 0 0 0',
    height: '64px',
  },
};

export default React.memo(CouponsSection);
