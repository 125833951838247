import React, { useCallback, useMemo } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import PixIcon from '@mui/icons-material/Pix';
import Layout from '../../component/Layout';
import { cartState, useCartItemsCount, useCartValue } from '../../state/cart';
import OrderReviewValues, { OrderReviewValuesProps } from './OrderReviewValues';
import OrderReviewSection from './OrderReviewSection';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { ScreenRoutes } from '../../router';
import { useAddGlobalLoading, useRemoveGlobalLoading } from '../../state/loading';
import { PixAlertStates, PosAlertStates } from '../../utils/alert';
import { useRecoilState } from 'recoil';
import { userState } from '../../state/user';
import { createPixOrder } from '../../api/order';
import { authState } from '../../state/auth';
import { alertState } from '../../state/alert';
import { posState } from '../../state/pos';
import { orderState } from '../../state/order';
import CouponsSection from './CouponsSection';
import { couponState } from '../../state/coupon';
import { centsToReal, priceToBrl } from '../../utils/conversion';

const PixActionPlaceholder = React.memo(styled.div`height: 120px;`);

const OrderReview: React.FC = () => {
  const cartItemsCount = useCartItemsCount();
  const cartValue = useCartValue();
  const navigate = useNavigate();
  const [user] = useRecoilState(userState);
  const [cart] = useRecoilState(cartState);
  const [auth] = useRecoilState(authState);
  const [, setAlert] = useRecoilState(alertState);
  const [pos] = useRecoilState(posState);
  const [, setOrder] = useRecoilState(orderState);
  const [coupon] = useRecoilState(couponState);
  const addGlobalLoading = useAddGlobalLoading();
  const removeGlobalLoading = useRemoveGlobalLoading();

  const values = useMemo<OrderReviewValuesProps[]>(() => [
    { title: 'Quantidade de Produtos', value: cartItemsCount.toString() },
    { title: 'Subtotal', value: priceToBrl(cartValue) },
    { title: 'Desconto', value: coupon.selected ? `R$ ${centsToReal(coupon.selected.value)}` : 'R$ 0,00' },
    { title: 'Total', value: priceToBrl(cartValue - ((coupon.selected?.value ?? 0) / 100)), bold: true },
  ], [cartItemsCount, cartValue, coupon.selected]);

  const handlePixPayment = useCallback(async () => {
    if (!pos.current) {
      return setAlert({ type: PosAlertStates.POS_SELECTION_NEEDED_ERROR });
    }

    try {
      addGlobalLoading();
      const pixOrder =
        await createPixOrder(
          user.id,
          {
            user,
            posName: pos.current.name,
            items: Object.values(cart.items),
            couponId: coupon.selected ? coupon.selected.id : undefined
          },
          auth.token
        );
      
      setOrder((prevOrder) => ({ ...prevOrder, current: pixOrder }));
      navigate(ScreenRoutes.PIX_REVIEW);
    } catch (error) {
      setAlert({ type: PixAlertStates.PIX_GENERATE_ERROR });
    } finally {
      removeGlobalLoading();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.token, cart, navigate, setAlert, user, coupon.selected]);

  return (
    <>
      <Layout title='Revise Seu Pedido' hiddenTabNavigation>
        {/*
        [TODO] create proprietary order reference
        <Typography variant='subtitle2' sx={style.orderNumber}>
          Número do Pedido: {getOrderNumber(order.current?.pixOrderReferenceId as string) ?? ''}
        </Typography> */}
        <Box sx={style.orderNumber}>
          <OrderReviewSection title='Cupons de Desconto'>
            <CouponsSection />
          </OrderReviewSection>
        </Box>
        <Box sx={style.section}>
          <Typography sx={style.title} variant='h6'>
            Resumo de Valores
          </Typography>
          {values.map((value) => (
            <OrderReviewValues key={value.title} {...value} />
          ))}
        </Box>
      </Layout>
      
      <PixActionPlaceholder />
      <Card sx={style.action}>
        <CardActionArea onClick={handlePixPayment} sx={style.actionArea}>
          <PixIcon sx={style.actionIcon} />
          <Typography sx={style.actionText}>
            Pagar com Pix
          </Typography>
        </CardActionArea>
      </Card>
    </>
  );
};

const style = {
  orderNumber: {
    margin: '32px 0',
    fontWeight: '400',
  },
  section: {
    marginBottom: '40px',
  },
  title: {
    fontSize: '14px',
  },
  addCard: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '4px 2px',
    margin: '4px 0 0 0',
    textAlign: 'left',
    height: '64px',
    backgroundColor: 'primary.main',
    borderBottomRightRadius: '4px',
    borderBottomLeftRadius: '4px',
    '> h6': {
      padding: '0 24px 0 16px',
      color: 'custom.white',
    }
  },
  addCardIcon: {
    fontSize: '40px',
    transform: 'rotate(-8deg)',
    margin: '0 8px',
    color: 'custom.white',
  },
  action: {
    borderRadius: 0,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    height: '120px',
    zIndex: 1,
    backgroundColor: 'primary.main',
  },
  actionArea: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  actionIcon: {
    fontSize: '52px',
    color: 'custom.white',
  },
  actionText: {
    fontFamily: "'Roboto Condensed', sans-serif",
    fontSize: '26px',
    fontWeight: 'bold',
    color: 'custom.white',
    padding: '0 16px',
    textTransform: 'uppercase',
  }
};

export default React.memo(OrderReview);
