import apiClient from ".";
import { CreateUserRequest, ReadUserResponse, UpdatePasswordRequest, UpdateUserRequest, User } from "../model/user";

export async function readUser(id: string, token: string): Promise<ReadUserResponse> {
  const response = await apiClient.get(`/users/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
}

export async function createUser(user: CreateUserRequest): Promise<void> {
  const response = await apiClient.post('/users', user);
  return response.data;
}

export async function updateUser(id: string, user: UpdateUserRequest, token: string): Promise<User> {
  const response = await apiClient.put(`/users/${id}`, user, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
}

export async function updateUserPassword(
  userId: string,
  updatePassword: UpdatePasswordRequest,
  token: string
): Promise<void> {
  const response = await apiClient.put(`/users/${userId}/password`, updatePassword, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
}
