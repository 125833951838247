import * as React from 'react';
import Box from '@mui/material/Box';
import PixIcon from '@mui/icons-material/Pix';

const PixIllustration: React.FC = () => (
  <Box sx={style.imgContainer}>
    <img src='/assets/wait.svg' alt='Carrinho vazio' />
    <PixIcon sx={style.pixIcon} />
  </Box>
);

const style = {
  imgContainer: {
    position: 'relative',
    width: '220px',
    height: '134px',
    marginTop: '32px',
    '> img': {
      margin: 'auto',
      width: '100%',
      height: '100%',
    }
  },
  pixIcon: {
    fontSize: '42px',
    color: 'white',
    position: 'absolute',
    top: '24px',
    left: '7px',
    zIndex: 1,
  },
}

export default React.memo(PixIllustration);
