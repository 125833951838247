import apiClient from ".";
import { UserLoginRequest, UserLoginResponse } from "../model/auth";

export async function login(userLogin: UserLoginRequest): Promise<UserLoginResponse> {
  const response = await apiClient.post('/auth', userLogin);
  const { userId, token } = response.data;

  return { token, userId };
}

export async function logout(token: string): Promise<void> {
  const response = await apiClient.delete(`/auth`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
}

export async function forgotPassword(email: string): Promise<void> {
  const response = await apiClient.put('/auth/password', {
    email
  });
  return response.data;
}