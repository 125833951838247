export const namefy = (name: string) => name.charAt(0).toUpperCase() + name.slice(1)

export const productfy = (name: string, limit = 35) => {
  const casedName = name
    .split(' ')
    .map((word) => word.toUpperCase())
    .join(' ')

  if (casedName.length <= limit) {
    return casedName;
  }

  if (casedName[limit - 2] === ' ') {
    return casedName.slice(0, limit - 2) + '…';
  }

  return casedName.slice(0, limit - 1) + '…';
}