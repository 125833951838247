import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

export interface OrderReviewSectionProps {
  title: string,
  children: React.ReactNode,
}

const OrderReviewSection: React.FC<OrderReviewSectionProps> =
  React.memo(({ title, children }) => (
    <Box sx={style.section}>
      <Typography sx={style.title} variant='h6'>{title}</Typography>
      <Paper elevation={4}>
        {children}
      </Paper>
    </Box>
  ));


const style = {
  section: {
    marginBottom: '40px',
  },
  title: {
    marginBottom: '8px',
    fontSize: '14px',
  },
};

export default OrderReviewSection;
