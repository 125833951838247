import apiClient from ".";
import { PopulatedCoupon } from "../model/coupon";

export async function getCoupons(userId: string, token: string): Promise<PopulatedCoupon[]> {
  const response = await apiClient.get(`/users/${userId}/coupons`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data;
}
