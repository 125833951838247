import React from 'react';
import Box from '@mui/material/Box';
import Layout from '../../component/Layout';
import BasicInformation from './BasicInformation';
import PasswordInformation from './PasswordInformation';
import { globalStyles } from '../../theme';

const Profile = () => {
  return (
    <Layout title="Meu Perfil" hiddenBackButton>
      <Box sx={[globalStyles.section, style.container]}>
        <BasicInformation />
      </Box>
      <Box sx={globalStyles.section}>
        <PasswordInformation />
      </Box>
    </Layout>
  );
};

const style = {
  container: {
    marginTop: '32px',
  }
};

export default React.memo(Profile);
