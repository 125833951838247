import React, { useCallback, useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import Container from '@mui/material/Container';
import { useRecoilState } from 'recoil';
import Login from './Login';
import Register from './Register';
import ForgotPassword from './ForgotPassword';
import { authScreenFlowState } from '../../state/authScreenFlow';
import { AuthScreenMode } from '../../model/screen';

const Auth: React.FC = () => {
  const [authScreen, setAuthScreen] = useState<AuthScreenMode>('login');
  const [authScreenFlow, setAuthScreenFlow] = useRecoilState(authScreenFlowState);

  const handleOnDrawerClose = useCallback(
    () => setAuthScreenFlow({ isOpen: false }),
    [setAuthScreenFlow]
  );

  useEffect(() => {
    setAuthScreen('login')
  }, [authScreenFlow.isOpen, setAuthScreen]);

  return (
    <Drawer
      anchor="bottom"
      open={authScreenFlow.isOpen}
      onClose={handleOnDrawerClose}
    >
      <Container sx={style.container}>
        {authScreen === 'login' && <Login setAuthScreen={setAuthScreen} />}
        {authScreen === 'register' && <Register setAuthScreen={setAuthScreen} />}
        {authScreen === 'forgot-password' && <ForgotPassword setAuthScreen={setAuthScreen} />}
      </Container>
    </Drawer>
  );
};

const style = {
  container: {
    padding: 0,
    height: '332px',
    width: '100%',
  }
}

export default Auth;
