import React, { useCallback, useState } from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import AuthLayout from '../../component/AuthLayout';
import { AuthScreenMode } from '../../model/screen';
import TextField from '../../component/TextField';
import syntaxValidator from '../../utils/validator';
import { forgotPassword } from '../../api/auth';
import { useRecoilState } from 'recoil';
import { alertState } from '../../state/alert';
import { AuthAlertStates } from '../../utils/alert';

interface ForgotPasswordProps {
  setAuthScreen: (screen: AuthScreenMode) => void;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({ setAuthScreen }) => {
  const [email, setEmail] = useState({ value: '', error: false });
  const [loading, setLoading] = useState(false);
  const [, setAlert] = useRecoilState(alertState);

  const handleForgotPassword = useCallback(async () => {
    if (!syntaxValidator.email(email.value)) {
      return setEmail((prevEmail) => ({ ...prevEmail, error: true }));
    }
    try {
      setLoading(true);
      await forgotPassword(email.value);
      setAlert({ type: AuthAlertStates.FORGOT_PASSWORD_SUCCESS });
      setAuthScreen('login');
    } catch (error) {
      setAlert({ type: AuthAlertStates.FORGOT_PASSWORD_ERROR });
    } finally {
      setLoading(false);
    }

  }, [email.value, setAlert, setAuthScreen])

  const handleRegister = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setAuthScreen('register');
  }, [setAuthScreen]);

  const handleLogin = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setAuthScreen('login');
  }, [setAuthScreen]);

  const handleEmailUpdate = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setEmail((prevEmail) => ({ ...prevEmail, value, error: false }));
  }, []);

  return (
    <AuthLayout
      onAction={handleForgotPassword}
      title='Esqueceu a senha?'
      subtitle='Lembrou a senha?'
      subtitleAction='Volte para o Login'
      onSubtitleAction={handleLogin}
      actionText='Enviar'
      loading={loading}
    >
      <TextField
        label="Email"
        type="email"
        value={email.value}
        onChange={handleEmailUpdate}
        error={email.error}
        helperText={email.error ? "E-mail inválido" : ''}
      />
      <Typography sx={style.forgotPassword} variant="caption">
        Não está registrado?
        <Link sx={style.signin} onClick={handleRegister}>
          Registre-se
        </Link>
      </Typography>
    </AuthLayout>
  );
};

const style = {
  forgotPassword: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '4px',
  },
  signin: {
    marginLeft: '4px',
  }
}

export default React.memo(ForgotPassword);
