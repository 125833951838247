import { atom } from 'recoil';

export interface AuthScreenFlowAtom {
  isOpen: boolean;
}

const defaultAuthScreenFlowState: AuthScreenFlowAtom = {
  isOpen: false,
}

export const authScreenFlowState = atom<AuthScreenFlowAtom>({
  key: 'authScreenFlowState',
  default: defaultAuthScreenFlowState,
});
