import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export interface OrderReviewValuesProps {
  title: string,
  value: string,
  bold?: boolean,
}

const OrderReviewValues: React.FC<OrderReviewValuesProps> = 
  React.memo(({ title, value, bold }) => (
    <Box sx={style.valuesContainer}>
      <Typography variant='subtitle2' sx={bold ? { fontWeight: '600 !important' } : {}}>{title}</Typography>
      <Typography variant='subtitle2'  sx={bold ? { fontWeight: '600 !important' } : {}}>{value}</Typography>
    </Box>
  ));

const style = {
  valuesContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '> h6': {
      fontWeight: 'normal',
    }
  },
};

export default OrderReviewValues;
