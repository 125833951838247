import React, { useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { cartState, useCartValue } from '../../state/cart';
import { useAddCartItem } from '../../state/cart';
import { useRemoveCartItem } from '../../state/cart';
import { AMLabsProduct } from '../../model/product';
import CartProductCard from '../../component/CartProductCard';

interface FilledCartProps {
  quantity: number;
}

const FilledCart: React.FC<FilledCartProps> = React.memo(({ quantity }) => {
  const [cart] = useRecoilState(cartState);
  const addCartItem = useAddCartItem({});
  const removeCartItem = useRemoveCartItem();
  const value = useCartValue();

  const handleAddClick = useCallback((product: AMLabsProduct) => {
    addCartItem(product);
  }, [addCartItem]);

  const handleRemoveClick = useCallback((product: AMLabsProduct) => {
    removeCartItem(product);
  }, [removeCartItem]);

  const cartItems = useMemo(() => Object.values(cart.items), [cart.items]);

  return (
    <>
      <Box sx={style.cardsContainer}>
        {cartItems.map((item) =>
          <CartProductCard
            key={item.product.id}
            product={item.product}
            quantity={item.quantity}
            onRemoveClick={handleRemoveClick}
            onAddClick={handleAddClick}
          />)}
      </Box>
      <Box sx={style.info}>
        <Typography variant="caption">
          {quantity} items. Total: R$ {value.toFixed(2)}
        </Typography>
      </Box>
    </>
  );
});

const style = {
  cardsContainer: {
    marginTop: '16px',
  },
  info: {
    textAlign: 'right',
    width: '100%',
    paddingBottom: '16px',
  }
};

export default FilledCart;
