import React, { useCallback } from 'react';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { authScreenFlowState } from '../state/authScreenFlow';
import { useRecoilState } from 'recoil';
import CircularProgress from '@mui/material/CircularProgress';
import { globalStyles } from '../theme';
import FixedCta from './FixedCta';

interface AuthLayoutProps {
  children: React.ReactNode;
  onAction: () => void;
  actionText: string;
  title: string;
  subtitle: string;
  subtitleAction: string;
  onSubtitleAction: (e: React.MouseEvent<HTMLElement>) => void;
  loading?: boolean;
}

const AuthLayout: React.FC<AuthLayoutProps> = (
  { children, onAction, actionText, title, subtitle, subtitleAction, onSubtitleAction, loading }
) => {
  // eslint-disable-next-line
  const [authScreenFlow, setAuthScreenFlow] = useRecoilState(authScreenFlowState);

  const handleCancel = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setAuthScreenFlow({ isOpen: false });
  }, [setAuthScreenFlow])

  return (
    <Container sx={style.container}>
      <Container sx={style.header}>
        <Typography sx={style.title} variant="h5">{title}</Typography>
        <Button sx={style.cancel} onClick={handleCancel}>Cancelar</Button>
      </Container>
      <Container sx={style.subHeader}>
        {(Boolean(subtitle)) && <Typography sx={style.subHeaderText} variant="body2">
          {subtitle}
          {(Boolean(subtitleAction)) && <Link sx={style.subHeaderAction} onClick={onSubtitleAction}>
            {subtitleAction}
          </Link>}
        </Typography>}
      </Container>
      <Container sx={style.contentContainer}>
        {children}
      </Container>
      <FixedCta disabled={loading} onClick={onAction}>
        {loading ? <CircularProgress size={globalStyles.circularProgress.size} /> : actionText}
      </FixedCta>
    </Container>
  );
};

const style = {
  container: {
    padding: '6px 0 0 0',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    padding: 0,
  },
  title: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '0.9rem',
    fontWeight: 600,
  },
  cancel: {
    fontWeight: 600,
    fontSize: '0.75rem',
    color: 'grey.600',
    textTransform: 'none',
  },
  subHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px',
    color: 'grey.600',
    backgroundColor: 'grey.100',
    margin: '8px 0',
  },
  subHeaderText: {
    fontWeight: 600,
    fontSize: '0.75rem',
  },
  subHeaderAction: {
    marginLeft: '12px'
  },
  contentContainer: {
    paddingBottom: '16px',
  }
}

export default React.memo(AuthLayout);
