import apiClient from ".";
import {
  CreatePixOrderRequest,
  CreatePixOrderResponse,
  ReadOrderStatusResponse,
  Order
} from "../model/order";

export async function getOrders(userId: string, token: string): Promise<Order[]> {
  const response = await apiClient.get(`/users/${userId}/orders`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data;
}

export async function getOrderStatus(orderId: string): Promise<ReadOrderStatusResponse> {
  const response = await apiClient.get(`/orders/${orderId}/status`);

  return response.data;
}

export async function createPixOrder(
  userId: string,
  pixOrderData: CreatePixOrderRequest,
  token: string
): Promise<CreatePixOrderResponse> {

  const response = await apiClient.post(
    `/users/${userId}/orders`,
    pixOrderData,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return response.data;
}
