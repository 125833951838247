import React, { ErrorInfo } from "react";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import grey from '@mui/material/colors/grey';
import { globalStyles } from "../theme";

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error;
  info: ErrorInfo;
}

/**
 * There is currently no way to write an error boundary as a function component. 
 * However, you don’t have to write the error boundary class yourself.
 * For example, you can use react-error-boundary instead.
 * https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
 */
class ErrorBoundary extends React.Component<object, ErrorBoundaryState> {
  constructor(props: object) {
    super(props);
    this.state = {
      hasError: false,
      error: { name: '', message: '', stack: '' },
      info: { componentStack: '' }
    };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch = (error: Error, info: ErrorInfo) => {
    console.error(`From Boundary. [Error: ${error}], [Info: ${info}]`);
    this.setState({ error, info });
  };

  render() {
    return (
      <Container sx={style.error}>
        <img src='/assets/server-down.svg' alt='Carrinho vazio' />
        <Typography variant='h5' sx={style.text} color={grey[400]}>
          Ops! Encontramos um obstáculo. Retorne ao início e tente novamente.
        </Typography>
        <Button
          variant="contained"
          fullWidth
          disableElevation
          sx={[globalStyles.radiusButton, style.button]}
          onClick={() => {
            // local storage can be updated and break the page, lets remove it
            window.localStorage.clear();
            window.location.replace('/')
          }}
        >
          INÍCIO
        </Button>
      </Container>
    );
  }
}

const style = {
  error: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '90vh',
    '> img': {
      width: '60%',
    }
  },
  text: {
    textAlign: 'center',
    width: '80%',
    fontWeight: 700,
    marginTop: '24px',
    fontFamily: "'Roboto Condensed', sans-serif",
  },
  button: {
    marginTop: '16px',
  }
};


export default ErrorBoundary;
