// eslint-disable-next-line no-shadow
export enum Stage {
  DEV = 'dev',
  PROD = 'prod'
}

interface EnvironmentVariables {
  STAGE: Stage;
}

const env: EnvironmentVariables = {
  STAGE: process.env.REACT_APP_STAGE === Stage.PROD ? Stage.PROD : Stage.DEV
};

export default env;
