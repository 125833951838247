import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { Order } from "../../model/order";
import CopyPaste from "../../component/CopyPaste";
import PendingIcon from '@mui/icons-material/Pending';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { PixAlertStates } from "../../utils/alert";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { frontExpireTimeFromDate, getOrderNumber } from "../../utils/order";
import { useRecoilState } from "recoil";
import { alertState } from "../../state/alert";
import grey from "@mui/material/colors/grey";
import { globalStyles } from "../../theme";
import { centsToReal } from "../../utils/conversion";
import { productfy } from "../../utils/naming";
import { useOrderPolling, useRefreshOrders } from "../../state/order";

interface OrderCardProps {
  order: Order;
}

export const OrderWaitCard: React.FC<OrderCardProps> = React.memo(({ order }) => {
  const [timeLeft, setTimeLeft] = useState(frontExpireTimeFromDate(order.expirationDate));
  const [, setAlert] = useRecoilState(alertState);
  const refreshOrders = useRefreshOrders();

  const handlePaymentSuccess = useCallback(() => {
    setAlert({ type:  PixAlertStates.PIX_PAYMENT_SUCCESS });
    refreshOrders();    
  }, [refreshOrders, setAlert]);

  const handlePaymentExpired = useCallback(() => {
    setAlert({ type: PixAlertStates.PIX_TIMEOUT_ERROR });
    refreshOrders();
  }, [refreshOrders, setAlert]);

  useOrderPolling(order.id, order.expirationDate, handlePaymentSuccess, handlePaymentExpired);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (timeLeft - 1 <= 0) {
        setAlert({ type: PixAlertStates.PIX_TIMEOUT_ERROR });
        return;
      } 
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearTimeout(timer);
  }, [setAlert, timeLeft]);

  const progress = useMemo(() => (Math.round(timeLeft) * 100) / 300, [timeLeft]);
  const minutes = useMemo(() =>  Math.floor(Math.round(timeLeft) / 60), [timeLeft]);
  const seconds = useMemo(() => `0${Math.round(timeLeft) % 60}`.slice(-2), [timeLeft]);

  return (
    <Card elevation={3} sx={style.card}>
      <Box sx={style.header}>
        <Typography sx={[globalStyles.sectionTitle, style.cardTitle]}>
          Aguardando Pagamento
        </Typography>
        <PendingIcon sx={style.cardIcon} color='warning' />
      </Box>
      <Box>
        <Typography sx={style.text}>
          Código do pedido: {getOrderNumber(order.info.reference_id)}
        </Typography>
        <Typography sx={style.text}>
          Valor: R$ {centsToReal(order.totalAmount)}
        </Typography>
        <Typography sx={style.text}>
          Data: {new Date(order.createdAt).toLocaleDateString()}
          , às {new Date(order.createdAt).toLocaleTimeString(
            navigator.language, { hour: '2-digit', minute: '2-digit' }
          )}
        </Typography>
      </Box>
      <Box sx={style.products}>
        <Typography sx={style.text}>
          Produtos:
        </Typography>
        {order.info.items.map((item) => (
          <Box sx={style.productContainer} key={item.name}>
            <Typography sx={[style.text, style.product]}>
              {productfy(item.name, 26)} {item.quantity}x R$ {centsToReal(item.unit_amount)}
            </Typography>
          </Box>
        ))}
      </Box>
      <CopyPaste value={order.info.qr_codes[0].text as string} />
      <Typography sx={[style.timeText, style.textTimingInfo]} variant="subtitle2">
        O tempo para você pagar termina em:
      </Typography>
      <Typography variant="h5" sx={style.time}>
        {minutes}:{seconds}
      </Typography>
      <LinearProgress sx={style.progress} variant="determinate" value={progress} />
    </Card>
  );
});

export const OrderPaidCard: React.FC<OrderCardProps> = React.memo(({ order }) => {
  return (
    <Card elevation={3} sx={style.card}>
      <Box sx={style.header}>
        <Typography sx={[globalStyles.sectionTitle, style.cardTitle]}>
          Código do pedido: {getOrderNumber(order.info.reference_id)}
        </Typography>
        <CheckCircleIcon sx={style.cardIcon} color='success' />
      </Box>
      <Box>
        <Typography sx={style.text}>
          Valor: R$ {centsToReal(order.totalAmount)}
        </Typography>
        <Typography sx={style.text}>
          Data: {new Date(order.createdAt).toLocaleDateString()}
          , às {new Date(order.createdAt).toLocaleTimeString(
            navigator.language, { hour: '2-digit', minute: '2-digit' }
          )}
        </Typography>
      </Box>
      <Box sx={[style.products, style.paidProducts]}>
        <Typography sx={style.text}>
          Produtos:
        </Typography>
        {order.info.items.map((item) => (
          <Box sx={style.productContainer} key={item.name}>
            <Typography sx={[style.text, style.product]}>
              {productfy(item.name, 26)} {item.quantity}x R$ {centsToReal(item.unit_amount)}
            </Typography>
          </Box>
        ))}
      </Box>
    </Card>
  );
});

export const OrderExpiredCard: React.FC<OrderCardProps> = React.memo(({ order }) => {
  return (
    <Card elevation={3} sx={style.card}>
      <Box sx={style.header}>
        <Typography sx={[globalStyles.sectionTitle, style.cardTitle]}>
          Código do pedido: {getOrderNumber(order.info.reference_id)}
        </Typography>
        <ErrorIcon sx={style.cardIcon} color='error' />
      </Box>
      <Box>
        <Typography sx={style.text}>
          Valor: R$ {centsToReal(order.totalAmount)}
        </Typography>
        <Typography sx={style.text}>
          Data: {new Date(order.createdAt).toLocaleDateString()}
          , às {new Date(order.createdAt).toLocaleTimeString(
            navigator.language, { hour: '2-digit', minute: '2-digit' }
          )}
        </Typography>
      </Box>
    </Card>
  );
});

const style = {
  card: {
    padding: '16px 16px 28px 16px',
    marginBottom: '16px',
  },
  cardTitle: {
    marginBottom: 0,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    marginBottom: '12px',
  },
  cardIcon: {
    position: 'absolute',
    right: 0,
  },
  wait: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
  },
  subtitle: {
    fontSize: '16px',
  },
  text: {
    fontSize: '14px',
  },
  products: {
    width: '100%',
    marginBottom: '24px',
  },
  paidProducts: {
    marginBottom: 0,
  },
  productContainer: {
    marginLeft: '16px',
  },
  product: {
    color: grey[600],
  },
  textTimingInfo: {
    marginTop: '24px',
  },
  timeText: {
    textAlign: 'left',
    fontWeight: 300,
    color: grey[600],
  },
  time: {
    fontWeight: 700,
    textAlign: 'left',
    marginBottom: '8px',
  },
  progress: {
    height: 10,
    borderRadius: 5,
  }
};
