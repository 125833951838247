import React, { useCallback, useMemo } from 'react';
import Layout from '../../component/Layout';
import { useCartItemsCount, useCartValue } from '../../state/cart';
import EmptyCart from './EmptyCart';
import FilledCart from './FilledCart';
import { ScreenRoutes } from '../../router';
import { useNavigate } from 'react-router-dom';
import FixedCta from '../../component/FixedCta';

// [TODO] Create const file
const MIN_CART_VALUE = 2;

const Cart: React.FC = () => {
  const cartItemsCount = useCartItemsCount();
  const cartValue = useCartValue();
  const navigate = useNavigate();

  const handleActionClick = useCallback(() => {
    navigate(ScreenRoutes.ORDER_REVIEW);
  }, [navigate]);

  const disabled = useMemo(() => (cartItemsCount === 0 || cartValue < MIN_CART_VALUE), [cartItemsCount, cartValue]);

  return (
    <>
      <Layout title='Carrinho' hiddenTabNavigation>
        {
          cartItemsCount === 0 ? <EmptyCart /> : <FilledCart quantity={cartItemsCount} />
        }
      </Layout>
      <FixedCta disabled={disabled} onClick={handleActionClick}>
        Concluir Compra
      </FixedCta>
    </>
  );
};

export default React.memo(Cart);
