import { atom, useRecoilState } from 'recoil';
import { localStorageEffect } from './persistence';
import { useCallback } from 'react';
import { userState } from './user';
import { authState } from './auth';
import { alertState } from './alert';
import { CouponAlertStates } from '../utils/alert';
import { PopulatedCoupon } from '../model/coupon';
import { getCoupons } from '../api/coupon';
import { calculateDiscount } from '../utils/coupon';
import { useCartValue } from './cart';
import { realToCents } from '../utils/conversion';

export interface CouponAtom {
  // add any necessary fields here
  list: {
    items: PopulatedCoupon[];
    loading: boolean;
  },
  selected?: {
    id: string;
    value: number;
  }
}

export const defaultCouponState: CouponAtom = {
  list: {
    items: [],
    loading: false,
  },
}

export const couponState = atom<CouponAtom>({
  key: 'couponState',
  default: defaultCouponState,
  effects: [
    localStorageEffect('couponStatePersistence')
  ]
});

export const useRefreshCoupons = () => {
  const [, setCoupon] = useRecoilState(couponState);
  const [user] = useRecoilState(userState);
  const [auth] = useRecoilState(authState);
  const [, setAlert] = useRecoilState(alertState);

  return useCallback(async () => {
    setCoupon((prevCoupon) => ({ ...prevCoupon, list: { ...prevCoupon.list, loading: true } }));
    try {
      const coupons = await getCoupons(user.id, auth.token);
      setCoupon((prevCoupon) => ({ ...prevCoupon, list: { items: coupons, loading: false } }));
    } catch (exception) {
      setAlert({ type: CouponAlertStates.RETRIEVE_COUPON_ERROR });
      setCoupon((prevCoupon) => ({ ...prevCoupon, list: { ...prevCoupon.list, loading: false } }));
    }
  }, [setCoupon, user.id, auth.token, setAlert]);
}

export const useSelectCoupon = () => {
  const [, setCoupon] = useRecoilState(couponState);
  const amount = realToCents(useCartValue());

  return useCallback((coupon?: PopulatedCoupon) => {
    const value = coupon ? calculateDiscount(coupon, amount) : 0;
    setCoupon((prevCoupon) => ({ ...prevCoupon, selected: coupon ? { id: coupon.id, value } : undefined }));
  }, [amount, setCoupon]);
}
