export const centsToReal = (cents: number) => {
  return (cents / 100).toFixed(2).replace('.', ',');
}

export const realToCents = (real: number) => {
  return Math.round(real * 100);
}

export const priceToBrl = (real: number) => {
  return `R$ ${real.toFixed(2).replace('.', ',')}`;
}
