import { Order, OrderStatus } from "../model/order";

export const isWaitingOrder = (order: Order) => {
  const currentDate = new Date();
  const expirationDate = frontExpireDate(order);

  return order.status === OrderStatus.CREATED && expirationDate >= currentDate;
};

export const isExpiredOrder = (order: Order) => {
  const currentDate = new Date();
  const expirationDate = frontExpireDate(order);

  return order.status === OrderStatus.CREATED && expirationDate < currentDate;
}

export const isPaidOrder = (order: Order) => {
  // is paid
  return order.status === OrderStatus.PAID;
}

export const mostRecent = (u: Order, v: Order) => new Date(v.createdAt).getTime() - new Date(u.createdAt).getTime();

// is created and (expirationDate - 3 minutes) not passed (backend time is 3 minutes ahead)
export const frontExpireDate = (order: Order): Date => {
  const expirationDateTime = new Date(order.expirationDate).getTime();
  const frontExpirationTime = expirationDateTime - 3 * 60 * 1000; // Subtract 3 minutes from expirationDate

  return new Date(frontExpirationTime);
}

export const frontExpireTimeFromDate = (expirationDate: string): number => {
  const expirationDateTime = new Date(expirationDate).getTime();
  const frontExpirationTime = expirationDateTime - 3 * 60 * 1000; // Subtract 3 minutes from expirationDate

  return (frontExpirationTime - Date.now()) / 1000;
}

export const getOrderNumber = (referenceId = '') => referenceId.substring(5, 12);
