import { atom, selector, useSetRecoilState } from 'recoil';

export type GlobalLoadingAtom = number;

export const defaultGlobalLoadingState: GlobalLoadingAtom = 0;

export const globalLoadingState = atom<GlobalLoadingAtom>({
  key: 'globalLoadingState',
  default: defaultGlobalLoadingState,
});

export const globalLoadingOpenState = selector<boolean>({
  key: 'globalLoadingOpenState',
  get: ({ get }) => {
    const count = get(globalLoadingState);
    return count > 0;
  },
});

export const useAddGlobalLoading = () => {
  const setLoading = useSetRecoilState(globalLoadingState);
  return () => setLoading((count) => count + 1);
}

export const useRemoveGlobalLoading = () => {
  const setLoading = useSetRecoilState(globalLoadingState);
  return () => setLoading((count) => Math.max(0, count - 1));
}
