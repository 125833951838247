import { atom } from 'recoil';
import { UserGender } from '../model/user';
import { localStorageEffect } from './persistence';

export interface UserAtom {
  id: string;
  email: string;
  taxId: string;
  name: string;
  bornDate: string;
  lastName?: string;
  gender?: UserGender;
  cellphone?: string;
}

export const defaultUserState: UserAtom = {
  id: '',
  email: '',
  taxId: '',
  name: '',
  bornDate: '',
}

export const userState = atom<UserAtom>({
  key: 'userState',
  default: defaultUserState,
  effects: [
    localStorageEffect('userStatePersistence')
  ]
});
