import { atom, useRecoilState } from 'recoil';
import { CreatePixOrderResponse, Order, OrderStatus } from '../model/order';
import { localStorageEffect } from './persistence';
import { useCallback, useEffect } from 'react';
import { getOrderStatus, getOrders } from '../api/order';
import { isExpiredOrder } from '../utils/order';
import { userState } from './user';
import { authState } from './auth';
import { alertState } from './alert';
import { OrderAlertStates } from '../utils/alert';

export interface OrderAtom {
  // add any necessary fields here
  list: {
    items: Order[];
    loading: boolean;
  },
  current?: CreatePixOrderResponse;
}

export const defaultOrderState: OrderAtom = {
  list: {
    items: [],
    loading: false,
  },
}

export const orderState = atom<OrderAtom>({
  key: 'orderState',
  default: defaultOrderState,
  effects: [
    localStorageEffect('orderStatePersistence')
  ]
});

export const useRefreshOrders = () => {
  const [, setOrder] = useRecoilState(orderState);
  const [user] = useRecoilState(userState);
  const [auth] = useRecoilState(authState);
  const [, setAlert] = useRecoilState(alertState);

  return useCallback(async () => {
    setOrder((prevOrder) => ({ ...prevOrder, list: { ...prevOrder.list, loading: true } }));
    try {
      const orders = await getOrders(user.id, auth.token);
      setOrder((prevOrder) => ({ ...prevOrder, list: { items: orders, loading: false } }));
    } catch (exception) {
      setAlert({ type: OrderAlertStates.RETRIEVE_ORDER_ERROR });
      setOrder((prevOrder) => ({ ...prevOrder, list: { ...prevOrder.list, loading: false } }));
    }
  }, [setOrder, user.id, auth.token, setAlert]);
}

export const useOrderPolling = (
  orderId?: string, expirationDate?: string, onSuccess?: () => void, onExpired?: () => void
) => {
  // each 10 seconds go through all orders (including the current), and check if they are waiting for payment
  // if they are get order status, keep doing until they are paid or expired
  useEffect(() => {
    if (!orderId || !onSuccess) {
      return;
    }

    let timer: NodeJS.Timeout;
    const pollOrderStatus = async () => {
      try {
        const { status } = await getOrderStatus(orderId);
        if (onExpired && expirationDate && isExpiredOrder({ status, expirationDate } as unknown as Order)) {
          onExpired();
        } else if (status === OrderStatus.PAID) {
          onSuccess();
        } else {
          // Order is not paid yet, continue polling
          timer = setTimeout(pollOrderStatus, 10000);
        }
      } catch (e) {
        // Error occurred while getting order status, continue polling
        timer = setTimeout(pollOrderStatus, 10000);
      }
    };

    pollOrderStatus();

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [orderId, onSuccess, onExpired, expirationDate]);
}
