import { PopulatedCoupon } from "../model/coupon";

export const validateCoupon = (coupon: PopulatedCoupon, amount: number): boolean => {
  if (!coupon.active) return false;
  if (coupon.used) return false;
  if (coupon.expiresAt && coupon.expiresAt < new Date()) return false;
  if (coupon.rule.minAmount > amount) return false;
  return true;
};

export const calculateDiscount = (coupon: PopulatedCoupon, amount: number): number => {
  const proportionalValue = amount * ((coupon.rule.percent ?? 100) / 100);
  const discount = coupon.rule.limit ? Math.min(coupon.rule.limit, proportionalValue) : proportionalValue;
  return Math.round(discount);
}
