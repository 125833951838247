import * as React from 'react';
import MuiBackdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Theme } from '@mui/material/styles';
import { globalLoadingOpenState } from '../state/loading';
import { useRecoilValue } from 'recoil';

function Backdrop() {
  const loadingOpen = useRecoilValue(globalLoadingOpenState);

  return (
    <MuiBackdrop
      sx={style.backdrop}
      open={loadingOpen}
    >
      <CircularProgress color="inherit" />
    </MuiBackdrop>
  );
}

const style = {
  backdrop: {
    color: 'custom.white',
    zIndex: (theme: Theme) => theme.zIndex.drawer + 1,
  }
}

export default React.memo(Backdrop);
