


import React, { SyntheticEvent, useCallback, useMemo } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert, { AlertColor } from '@mui/material/Alert';
import Slide, { SlideProps } from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useRecoilState } from 'recoil';
import { AlertMessages, DefaultAlertStates } from '../utils/alert';
import { alertState } from '../state/alert';

type TransitionProps = Omit<SlideProps, 'direction'>;

function TransitionDown (props: TransitionProps) {
  return <Slide {...props} direction="down" />;
}

const SnackbarAlert: React.FC = () => {
  const [alert, setAlert] = useRecoilState(alertState);
  
  const handleClose = useCallback((e: Event | SyntheticEvent<unknown, Event>) => {
    if (e) {
      e.stopPropagation();
    }

    setAlert({
      type: DefaultAlertStates.CLOSED,
      clickHandler: undefined,
    });
  }, [setAlert]);

  const open = useMemo(() => alert.type !== DefaultAlertStates.CLOSED, [alert.type]);

  const severity: AlertColor | undefined = useMemo(() => {
    if (alert.type.indexOf('SUCCESS') > -1) return 'success';
    if (alert.type.indexOf('ERROR') > -1) return 'error';
    if (alert.type.indexOf('WARNING') > -1) return 'warning';
    if (alert.type.indexOf('INFO') > -1) return 'info'
    return undefined;
  }, [alert.type]);

  if (!open) { // prevent rendering when closed
    return null;
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
      TransitionComponent={TransitionDown}
    >
      {<Alert
        elevation={6}
        severity={severity}
        sx={style.alert}
        onClick={alert.clickHandler}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={handleClose}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }  
      >
        {AlertMessages[alert.type]}
      </Alert>}
    </Snackbar>
  );
};

const style = {
  alert: {
    width: '100%'
  }
};

export default React.memo(SnackbarAlert);
