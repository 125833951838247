import { atom } from 'recoil';
import { AMLabsProduct } from '../model/product';
import { PointOfSale } from '../model/pos';
import { localStorageEffect } from './persistence';

export interface PoSAtom {
  current?: PointOfSale;
  inventory: AMLabsProduct[];
  list: PointOfSale[];
}

export const defaultPoSState: PoSAtom = {
  current: undefined,
  inventory: [],
  list: [],
};

export const posState = atom<PoSAtom>({
  key: 'posState',
  default: defaultPoSState,
  effects: [
    localStorageEffect('posStatePersistence')
  ]
});
