import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { RouterProvider } from 'react-router-dom'
import { RecoilRoot, useRecoilState } from 'recoil';
import { router } from './router';
import { theme } from './theme';
import Auth from './domain/auth';
import Backdrop from './component/Backdrop';
import { useAddGlobalLoading, useRemoveGlobalLoading } from './state/loading';
import { readUser } from './api/user';
import { useCallback, useEffect } from 'react';
import { authState, defaultAuthState } from './state/auth';
import { defaultUserState, userState } from './state/user';
import { authScreenFlowState } from './state/authScreenFlow';
import SnackbarAlert from './component/SnackbarAlert';
import { readProductsByPlanogramId } from './api/product';
import { posState } from './state/pos';
import { alertState } from './state/alert';
import { ProductAlertStates, PosAlertStates } from './utils/alert';
import { cartState, defaultCartState } from './state/cart';
import { defaultOrderState, orderState } from './state/order';
import { getPointOfSales } from './api/pos';
import { PointOfSale } from './model/pos';

function StateFullApp() {
  const [auth, setAuth] = useRecoilState(authState);
  const [, setAuthScreenFlow] = useRecoilState(authScreenFlowState);
  const [, setUser] = useRecoilState(userState);
  const [pos, setPos] = useRecoilState(posState);
  const [, setCart] = useRecoilState(cartState);
  const [, setAlert] = useRecoilState(alertState);
  const [, setOrder] = useRecoilState(orderState);
  const addGlobalLoading = useAddGlobalLoading();
  const removeGlobalLoading = useRemoveGlobalLoading();

  const handleBaseUserData = useCallback(async () => {
    if (!auth.isLoggedIn) {
      setUser(defaultUserState);
      setCart(defaultCartState);
      return;
    }

    try {
      // get user data
      addGlobalLoading();
      const user = await readUser(auth.userId, auth.token);
      setUser({
        id: user.id,
        email: user.email,
        taxId: user.taxId,
        name: user.name,
        bornDate: user.bornDate,
        lastName: user.lastName,
        gender: user.gender,
        cellphone: user.cellphone,
      });
    } catch (error) {
      setAuth(defaultAuthState);
      setUser(defaultUserState);
      setCart(defaultCartState);
      setOrder(defaultOrderState);
      setAuthScreenFlow({ isOpen: true });
    } finally {
      removeGlobalLoading();
    }
  // eslint-disable-next-line max-len, react-hooks/exhaustive-deps
  }, [auth.isLoggedIn, auth.userId, auth.token, setUser, setAuth, setAuthScreenFlow]);

  const handleInventoryData = useCallback(async () => {
    try {
      // simulate loading
      setPos((prev) => ({ ...prev, inventory: [] }));
      if (!pos.current) return;
      const products = await readProductsByPlanogramId(pos.current.planogramId);
      setPos((prev) => ({ ...prev, inventory: products }));
    } catch (error) {
      setAlert({ type: ProductAlertStates.PRODUCT_ERROR });
    }
  // eslint bug:
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pos.current?.planogramId, setAlert, setPos]);

  const handlePosData = useCallback(async () => {
    try {
      addGlobalLoading();
      const posList = await getPointOfSales();
      setPos((prev) => {
        const current =
          (!prev.current || posList.find(({ id }) => id === (prev.current as PointOfSale).id) === undefined) ?
            posList[0] : prev.current;

        return {
          ...prev,
          current,
          list: posList
        };
      });
    } catch (error) {
      setAlert({ type: PosAlertStates.RETRIEVE_POS_ERROR });
    } finally {
      removeGlobalLoading();
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => { handleBaseUserData() }, [handleBaseUserData]);
  useEffect(() => { handleInventoryData() }, [handleInventoryData]);
  useEffect(() => { handlePosData() }, [handlePosData]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Backdrop />
      <SnackbarAlert />
      <RouterProvider router={router} />
      <Auth />
    </ThemeProvider>
  )
}

function App() {
  return (
    <RecoilRoot>
      <StateFullApp />
    </RecoilRoot>
  );
}

export default App;
