import { createBrowserRouter } from "react-router-dom";
import Home from "./domain/home";
import Profile from "./domain/profile";
import Information from "./domain/information";
import Cart from "./domain/cart";
import Store from "./domain/store";
import OrderReview from "./domain/order-review";
import PixReview from "./domain/pix-review";
import Orders from "./domain/orders";
import ErrorBoundary from "./component/ErrorBoundary";

// eslint-disable-next-line no-shadow
export enum ScreenRoutes {
  HOME = '/',
  PROFILE = '/profile',
  INFORMATION = '/information',
  CART = '/cart',
  STORE = '/store',
  ORDER_REVIEW = '/order/review',
  PIX_REVIEW = '/order/review/pix',
  ORDERS = '/orders',
  // Pass the delta you want to go in the history stack.
  // For example, navigate(-1) is equivalent to hitting the back button.
  GO_BACK = -1,
}

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/profile',
    element: <Profile />,
  },
  {
    path: '/information',
    element: <Information />,
  },
  {
    path: '/cart',
    element: <Cart />,
  },
  {
    path: '/store',
    element: <Store />,
  },
  {
    path: '/order/review',
    element: <OrderReview />,
  },
  {
    path: '/order/review/pix',
    element: <PixReview />,
  },
  {
    path: '/orders',
    element: <Orders />,
  }
].map(route => ({
  ...route,
  // Error element is necessary due non-friendly react router error boundary
  errorElement: <ErrorBoundary />
})));
