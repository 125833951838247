import { buttonClasses } from '@mui/material/Button';
import grey from '@mui/material/colors/grey';
import { createTheme } from '@mui/material/styles';

const palette = {
  background: {
    default: grey[100],
  },
  primary: {
    main: '#0766FF',
    light: '#3884ff',
    dark: '#0032a0',
    contrastText: '#fff',
  },
  custom: {
    primary: {
      lighter: 'rgba(7, 102, 255, 0.08)',
    },
    disable: '#e4e4e4',
    white: '#fff',
  }
}

export const globalStyles = {
  sectionTitle: {
    fontWeight: 600,
    marginBottom: '16px',
    color: 'grey.800'
  },
  passwordIcon: {
    paddingBottom: '1px',
  },
  radiusButton: {
    borderRadius: '32px',
  },
  shrinkedLabelInput: {
    transform: 'scale(0.75)',
  },
  customInputContainer: {
    margin: '16px 0 0 0',
  },
  section: {
    marginTop: '52px',
  },
  circularProgress: {
    size: 32,
  },
};

export const theme = createTheme({
  palette: palette,
  components: {
    MuiBottomNavigationAction: {
      styleOverrides: {
        label: {
          marginTop: '0.15rem',
          fontSize: '0.70rem !important',
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: palette.primary.dark,
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        action: {
          margin: 'auto'
        },
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          [`&.${buttonClasses.disabled}`]: {
            backgroundColor: `${palette.custom.disable} !important`,
          }
        }
      }
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
      fontSize: '1rem',
    },
    caption: {
      fontWeight: 500,
      lineHeight: '1rem',
    }
  },
});
