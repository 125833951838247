import React from 'react';
import Card from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';

const OrderCardSkeleton: React.FC = React.memo(() => {
  return (
    <Card elevation={0} sx={style.card}>
      <Skeleton width={'100%'} height={140} animation='wave' variant="rectangular" />
      <Skeleton
        width={'100%'}
        height={20}
        animation='wave'
        variant="rectangular"
        sx={style.cardContentSkeleton}
      />
    </Card>
  );
});

const style = {
  card: {
    height: '100%',
    '> img': {
      height: '148px',
      objectFit: 'contain',
      padding: '8px 2px 0 2px',
    },
    marginTop: '24px',
    marginBottom: '16px',
  },
  cardContentSkeleton: {
    marginTop: '8px',
  }
};

export default OrderCardSkeleton;