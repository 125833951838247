import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import grey from '@mui/material/colors/grey';

const Brand: React.FC = () => {
  return (
    <Container sx={style.container}>
      <Link sx={style.link} target="_blank" rel="noopener" href="https://sennela.com/haconnector/terms/index.html">
        <Typography variant="caption">
          Termos de Uso & Condições
        </Typography>
      </Link>
      <img style={style.image} src='./assets/partnership-logo.png' alt='partnership' />
      <Typography variant='caption'>
        Home Audience Connector <br />
        <Link sx={style.link} target="_blank" rel="noopener" href="https://sennela.com/">Sennela.com</Link>
        {' '}- Todos os Direitos Reservados
      </Typography>
    </Container>
  );
};

const style = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', 
    textAlign: 'center',
    color: 'grey.600',
  },
  image: {
    maxWidth: '140px',
    margin: '16px 0',
  },
  link: {
    color: 'grey.600',
    textDecorationColor: grey[600],
  }
}

export default React.memo(Brand);