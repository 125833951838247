// eslint-disable-next-line no-shadow
export enum ProfileAlertStates {
  UPDATE_SUCCESS = 'UPDATE_SUCCESS',
  UPDATE_ERROR = 'UPDATE_ERROR',
}

// eslint-disable-next-line no-shadow
export enum AuthAlertStates {
  REGISTER_SUCCESS = 'REGISTER_SUCCESS',
  REGISTER_ERROR = 'REGISTER_ERROR',
  FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR',
  LOGIN_ERROR = 'LOGIN_ERROR',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
}

// eslint-disable-next-line no-shadow
export enum InformationAlertStates {
  MESSAGE_SUCCESS = 'MESSAGE_SUCCESS',
  MESSAGE_ERROR = 'MESSAGE_ERROR',
}

// eslint-disable-next-line no-shadow
export enum ProductAlertStates {
  PRODUCT_ERROR = 'PRODUCT_ERROR',
  PRODUCT_OUT_OF_STOCK_ERROR = 'PRODUCT_OUT_OF_STOCK_ERROR',
  PRODUCT_ADD_SUCCESS = 'PRODUCT_ADD_SUCCESS',
}

// eslint-disable-next-line no-shadow
export enum PixAlertStates {
  PIX_COPY_SUCCESS = 'PIX_COPY_SUCCESS',
  PIX_PAYMENT_SUCCESS = 'PIX_PAYMENT_SUCCESS',
  PIX_GENERATE_ERROR = 'PIX_GENERATE_ERROR',
  PIX_TIMEOUT_ERROR = 'PIX_TIMEOUT_ERROR',
}

// eslint-disable-next-line no-shadow
export enum OrderAlertStates {
  RETRIEVE_ORDER_ERROR = 'RETRIEVE_ORDER_ERROR',
}

// eslint-disable-next-line no-shadow
export enum CouponAlertStates {
  RETRIEVE_COUPON_ERROR = 'RETRIEVE_COUPON_ERROR',
  UNSELECTED_COUPON_ERROR = 'UNSELECTED_COUPON_ERROR',
}

// eslint-disable-next-line no-shadow
export enum PosAlertStates {
  RETRIEVE_POS_ERROR = 'RETRIEVE_POS_ERROR',
  POS_SELECTION_NEEDED_ERROR = 'POS_SELECTION_NEEDED_ERROR',
}

// eslint-disable-next-line no-shadow
export enum DefaultAlertStates {
  CLOSED = 'CLOSED',
}

export type AlertType =
  ProfileAlertStates |
  AuthAlertStates |
  InformationAlertStates |
  ProductAlertStates |
  PixAlertStates |
  OrderAlertStates |
  CouponAlertStates |
  PosAlertStates |
  DefaultAlertStates;

export const AlertMessages: Record<AlertType, string> = {
  [ProfileAlertStates.UPDATE_SUCCESS]: 'Atualizado com sucesso',
  [ProfileAlertStates.UPDATE_ERROR]: 'Atualização não pode ser realizada',
  [AuthAlertStates.REGISTER_SUCCESS]: 'Registrado com sucesso',
  [AuthAlertStates.REGISTER_ERROR]: 'Registro não pode ser realizado',
  [AuthAlertStates.FORGOT_PASSWORD_SUCCESS]: 'Email enviado com sucesso',
  [AuthAlertStates.FORGOT_PASSWORD_ERROR]: 'Email não pode ser enviado',
  [AuthAlertStates.LOGIN_ERROR]: 'Email ou senha incorretos',
  [AuthAlertStates.LOGIN_SUCCESS]: 'Login realizado com sucesso',
  [InformationAlertStates.MESSAGE_SUCCESS]: 'Mensagem enviada com sucesso',
  [InformationAlertStates.MESSAGE_ERROR]: 'Erro ao enviar mensagem',
  [ProductAlertStates.PRODUCT_ERROR]: 'Erro ao carregar produtos',
  [ProductAlertStates.PRODUCT_OUT_OF_STOCK_ERROR]: 'Estoque insuficiente',
  [ProductAlertStates.PRODUCT_ADD_SUCCESS]: 'Produto adicionado ao carrinho',
  [PixAlertStates.PIX_COPY_SUCCESS]: 'Pix copiado para área de transferência',
  [PixAlertStates.PIX_PAYMENT_SUCCESS]: 'Pagamento recebido com sucesso',
  [PixAlertStates.PIX_GENERATE_ERROR]: 'Erro ao gerar Pix, tente novamente',
  [PixAlertStates.PIX_TIMEOUT_ERROR]: 'Tempo de pagamento expirado',
  [OrderAlertStates.RETRIEVE_ORDER_ERROR]: 'Erro ao carregar pedidos',
  [CouponAlertStates.RETRIEVE_COUPON_ERROR]: 'Erro ao carregar cupons',
  [CouponAlertStates.UNSELECTED_COUPON_ERROR]: 'Cupom não se aplica',
  [PosAlertStates.RETRIEVE_POS_ERROR]: 'Erro ao carregar pontos de venda',
  [PosAlertStates.POS_SELECTION_NEEDED_ERROR]: 'Selecione um ponto de venda',
  [DefaultAlertStates.CLOSED]: '',
};
