import { atom } from 'recoil';
import { localStorageEffect } from './persistence';

export interface AuthAtom {
  userId: string;
  token: string;
  isLoggedIn: boolean;
}

export const defaultAuthState: AuthAtom = {
  userId: '',
  token: '',
  isLoggedIn: false,
};

export const authState = atom<AuthAtom>({
  key: 'authState',
  default: defaultAuthState,
  effects: [
    localStorageEffect('authStatePersistence')
  ]
});
