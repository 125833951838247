import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import grey from '@mui/material/colors/grey';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';

export interface HorizontalRadioProps {
  // eslint-disable-next-line @typescript-eslint/ban-types
  image: OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string; },
  title: string,
  description?: string,
  checked?: boolean,
  onClick: () => void,
}

const HorizontalRadio: React.FC<HorizontalRadioProps> =
  React.memo(({ image: Image, title, description, checked, onClick }: HorizontalRadioProps) => (
    <Box onClick={onClick} sx={style.horizontalRadio}>
      <Image sx={style.icon(checked)} />
      <Box sx={style.textContainer}>
        <Typography sx={style.text} variant='subtitle2'>{title}</Typography>
        {description && <Typography sx={style.text} variant='subtitle2'>{description}</Typography>}
      </Box>
      <Radio disableRipple sx={style.radio} checked={Boolean(checked)} />
    </Box>
  ));


const style = {
  horizontalRadio: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '4px 2px',
    margin: '4px 0 0 0',
    textAlign: 'left',
    ':nth-of-type(even)': {
      backgroundColor: grey[200],
    },
    height: '64px',
  },
  textContainer: {
    width: '100%',
  },
  text: {
    fontWeight: 'normal',
    textAlign: 'left',
    padding: '0 18px 0 12px',
    width: '100%',
    display: 'block',
    fontSize: '12px',
    lineHeight: '1.33',
  },
  icon: (checked?: boolean) => ({
    fontSize: '40px',
    transform: 'rotate(-8deg)',
    margin: '0 8px',
    color: checked ? 'primary.main' : grey[600],
  }),
  radio: {
    '& .MuiSvgIcon-root': {
      fontSize: '30px',
    },
  },
};
  

export default HorizontalRadio;
