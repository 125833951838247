import syntaxValidator from "./validator";

// Accept dd/mm/yyyy format
export function checkAdultAge(bornDate: string): boolean {
  if (!syntaxValidator.date(bornDate)) {
    return false;
  }

  const [day, month, year] = bornDate.split('/');
  const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  const now = new Date();

  const ageInMilliseconds = now.getTime() - date.getTime();
  const millisecondsInYear = 1000 * 60 * 60 * 24 * 365;
  const ageInYears = ageInMilliseconds / millisecondsInYear;

  return ageInYears >= 18;
}
