import { atom, useRecoilState } from 'recoil';
import { CartItem } from '../model/cart';
import { authState } from './auth';
import { authScreenFlowState } from './authScreenFlow';
import { AMLabsProduct } from '../model/product';
import { alertState } from './alert';
import { ProductAlertStates } from '../utils/alert';
import { localStorageEffect } from './persistence';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { ScreenRoutes } from '../router';
import { getPrice } from '../utils/product';

export interface CartAtom {
  items: Record<string, CartItem>;
}

export const defaultCartState: CartAtom = {
  items: {},
}

export const cartState = atom<CartAtom>({
  key: 'cartState',
  default: defaultCartState,
  effects: [
    localStorageEffect('cartStatePersistence')
  ]
});

export interface AddCartItemProps {
  showSuccess?: boolean;
}

export const useAddCartItem = ({ showSuccess } : AddCartItemProps) => {
  const [auth] = useRecoilState(authState);
  const [, setAuthScreenFlow] = useRecoilState(authScreenFlowState);
  const [cart, setCart] = useRecoilState(cartState);
  const [, setAlert] = useRecoilState(alertState);
  const navigate = useNavigate();

  const handleAlertSuccessClick = useCallback(
    () => navigate(ScreenRoutes.CART),
    [navigate]
  );

  return (product: AMLabsProduct) => {
    if (!auth.isLoggedIn) {
      return setAuthScreenFlow({ isOpen: true });
    }

    if (product.currentQuantity === 0) {
      return setAlert({ type: ProductAlertStates.PRODUCT_OUT_OF_STOCK_ERROR });
    }

    if (cart.items[product.id] && cart.items[product.id].quantity + 1 > product.currentQuantity) {
      return setAlert({ type: ProductAlertStates.PRODUCT_OUT_OF_STOCK_ERROR });
    }

    if (showSuccess) {
      setAlert({
        type: ProductAlertStates.PRODUCT_ADD_SUCCESS,
        clickHandler: handleAlertSuccessClick
      });
    }

    setCart((prevCart) => {
      const newCart = { items : { ...prevCart.items } };

      if (newCart.items[product.id] === undefined) {
        newCart.items[product.id] = {
          quantity: 1,
          product,
        };
      } else {
        newCart.items[product.id] = {
          ...newCart.items[product.id],
          quantity: newCart.items[product.id].quantity + 1,
        }
      }

      return newCart;
    });
  };
};

export const useRemoveCartItem = () => {
  const [, setCart] = useRecoilState(cartState);

  return (product: AMLabsProduct) => {
    setCart((prevCart) => {
      const newCart = { items : { ...prevCart.items } };

      if (newCart.items[product.id].quantity === 1) {
        delete newCart.items[product.id];
      } else {
        newCart.items[product.id] = {
          ...newCart.items[product.id],
          quantity: newCart.items[product.id].quantity - 1,
        }
      }

      return newCart;
    });
  };
}

export const useCartItemsCount = () => {
  const [cart] = useRecoilState(cartState);

  return Object.values(cart.items).reduce((acc, item) => {
    return acc + item.quantity;
  }, 0);
}

export const useCartValue = () => {
  const [cart] = useRecoilState(cartState);

  return Object.values(cart.items).reduce((acc, item) => {
    return acc + (item.quantity * getPrice(item.product));
  }, 0);
}