import { PopulatedCoupon } from './coupon';
import { type AMLabsProduct } from './product';
import { type User } from './user';

export interface PixCustomer {
  name: string
  email: string
  tax_id: string
}

export interface PixItem {
  name: string
  quantity: number
  unit_amount: number
}

export interface PixAddress {
  street: string
  number: string
  complement: string
  locality: string
  city: string
  region_code: string
  country: string
  postal_code: string
}

export interface PixQRCodeLink {
  rel: string
  href: string
  media: string
  type: string
}

export interface PixQRCode {
  id?: string
  expiration_date: string
  amount: {
    value: number
  }
  text?: string
  arrangements?: string[]
  links?: PixQRCodeLink[]
}

export interface PixAmountSummary {
  total: number
  paid: number
  refunded: number
}

export interface PixPaymentResponse {
  code: string
  message: string
}

export interface PixHolder {
  name: string
  tax_id: string
}

export interface PixPaymentMethod {
  type: string
  pix: {
    notification_id: string
    holder: PixHolder
  }
}

export interface PixChargeLink {
  rel: string
  href: string
  media: string
  type: string
}

export interface PixChargeAmount {
  value: number
  currency: string
  summary: PixAmountSummary
}

export interface PixCharge {
  id: string
  reference_id: string
  status: string
  created_at: string
  paid_at: string
  amount: PixChargeAmount
  payment_response: PaymentResponse
  payment_method: PixPaymentMethod
  links: PixChargeLink[]
  // eslint-disable-next-line @typescript-eslint/ban-types
  metadata: {}
}

export interface PixLinks {
  rel: string
  href: string
  media: string
  type: string
}

export interface PixOrder {
  id: string
  reference_id: string
  created_at: string
  customer: PixCustomer
  items: PixItem[]
  shipping: PixShipping
  qr_codes: PixQRCode[]
  charges?: PixCharge[]
  notification_urls: string[]
  links: PixLinks[]
}

export interface PixShipping {
  address: PixAddress
}

// eslint-disable-next-line no-shadow
export enum PaymentType {
  PIX = 'PIX',
}

// eslint-disable-next-line no-shadow
export enum OrderStatus {
  PAID = 'PAID',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  CREATED = 'CREATED',
  // [TODO] create status EXPIRED instead of deal with logic in the frontend
}

export type OrderInfo = PixOrder

export interface CreatePixOrderRequest {
  user: Omit<User, 'password'>
  posName: string
  items: CartItem[]
  couponId?: string
}

export interface CreatePixOrderResponse {
  qrCodeId: string
  orderId: string
  pixOrderId: string
  pixOrderReferenceId: string
  copyPaste: string
  expirationDate: string
}

export interface ReadOrderStatusResponse {
  status: OrderStatus
}

export interface Order {
  id: string
  type: PaymentType
  status: OrderStatus
  info: OrderInfo
  totalAmount: number
  userName: string
  userTaxId: string
  userEmail: string
  userId: string
  posName: string
  // 5min after the order is created, it expires, format YYYY-MM-DDTHH:mm:ss-03:00
  expirationDate: string
  readonly createdAt: Date
  readonly updatedAt: Date
  readonly coupon: PopulatedCoupon
}

export interface CartItem {
  quantity: number
  product: AMLabsProduct
}
