import React, { useCallback, useMemo } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import { useNavigate } from 'react-router-dom';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import IconButton from '@mui/material/IconButton';
import { useRecoilState } from 'recoil';
import { userState } from '../../state/user';
import { authState } from '../../state/auth';
import { useCartItemsCount } from '../../state/cart';
import { namefy } from '../../utils/naming';
import { ScreenRoutes } from '../../router';

const Hero: React.FC = () => {
  const navigate = useNavigate();
  const [user] = useRecoilState(userState);
  const [auth] = useRecoilState(authState);
  const cartItemsCount = useCartItemsCount();

  const welcomeText = useMemo(
    () => auth.isLoggedIn ? namefy(user.name) : 'bem-vindo',
    [auth.isLoggedIn, user.name]
  );

  const handleCartClick = useCallback(() => {
    navigate(ScreenRoutes.CART);
  }, [navigate]);

  return (
    <Container sx={style.container}>
      <Grid item xs={12}>
        <Typography variant="h3">Olá,</Typography>
        <Typography variant="h3" sx={style.welcome}>{welcomeText}</Typography>
      </Grid>
      <IconButton
        size='large'
        sx={style.button}
        edge="start"
        color="inherit"
        onClick={handleCartClick}
      >
        <Badge badgeContent={cartItemsCount} color="error">
          <ShoppingCartIcon />
        </Badge>
      </IconButton>
    </Container>
  );
};

const style = {
  container: {
    backgroundColor: 'primary.dark',
    color: 'primary.contrastText',
    height: '320px',
    borderRadius: '0 0 16% 16%',
    paddingTop: '40px',
    paddingLeft: '20px',
    paddingRight: '20px',
    position: 'relative',
  },
  welcome: {
    fontWeight: 600,
  },
  button: {
    position: 'absolute',
    right: '20px',
    top: '40px',
  }
};

export default React.memo(Hero);
  