import apiClient from ".";
import { AMLabsProduct } from "../model/product";

export async function readProductsByPlanogramId(planogramId: string): Promise<AMLabsProduct[]> {
  const response = await apiClient.get(
    `/products`,
    { params: { available: true, planogramId } }
  );

  return response.data;
}
